/* You can add global styles to this file, and also import other style files */
@import "./styles/button.css";
@import "./styles/layout.css";
@import "./styles/colors.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@aws-amplify/ui-angular/theme.css';
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

/* prettier-ignore */
:root {
  /*
   * see: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
   *
   * main color (and it's complement) in vanilla CSS
   *    - this shade arrangement matches material spec
   *    - antage blue 600 is the official antage blue
   */
   --antage-blue-50: #e4f1fa;  --antage-orange-50: #fbfceb;
  --antage-blue-100: #beddf3; --antage-orange-100: #f5f6cd;
  --antage-blue-200: #97c8ec; --antage-orange-200: #eff0ad;
  --antage-blue-300: #73b2e3; --antage-orange-300: #eaeb8f;
  --antage-blue-400: #5aa2de; --antage-orange-400: #e7e67b;
  --antage-blue-500: #4693d9; --antage-orange-500: #e3e16a;
  --antage-blue-600: #3f86cc; --antage-orange-600: #e1d163;
  --antage-blue-700: #3674ba; --antage-orange-700: #dcbc59;
  --antage-blue-800: #3064a8; --antage-orange-800: #d7a74f;
  --antage-blue-900: #244788; --antage-orange-900: #cc863f;

  /* (barebones) palettes for triadic colors */
   --antage-purple-50: #efe4f7;  --antage-pink-50: #f5e3ed;
  --antage-purple-100: #d4beec; --antage-pink-100: #e9b8d4;
  --antage-purple-200: #b892e0; --antage-pink-200: #dd8bb7;
  --antage-purple-300: #9b64d5; --antage-pink-300: #d25f9b;
  --antage-purple-400: #863fcc; --antage-pink-400: #cc3f86;
  --antage-purple-500: #6f0cc2; --antage-pink-500: #c81e70;
  --antage-purple-600: #6504bd; --antage-pink-600: #b71e6b;
  --antage-purple-700: #5700b4; --antage-pink-700: #a11d63;
  --antage-purple-800: #4900ae; --antage-pink-800: #8c1c5c;
  --antage-purple-900: #3200a0; --antage-pink-900: #67174f;

  /* (barebones) palettes for analogs of antage-blue */
   --antage-green-50: #d7f3f4;  --antage-indigo-50: #e9eafb;
  --antage-green-100: #9ae0e0; --antage-indigo-100: #c8c9f4;
  --antage-green-200: #3fcccc; --antage-indigo-200: #a3a6ed;
  --antage-green-300: #00b6b5; --antage-indigo-300: #7d82e5;
  --antage-green-400: #00a5a2; --antage-indigo-400: #6065df;
  --antage-green-500: #00938e; --antage-indigo-500: #4448d7;
  --antage-green-600: #008780; --antage-indigo-600: #3f3fcc;
  --antage-green-700: #00776f; --antage-indigo-700: #3634c0;
  --antage-green-800: #00675f; --antage-indigo-800: #2e28b4;
  --antage-green-900: #004b40; --antage-indigo-900: #1e0ba2;

  /* main color is antage blue */
  --main-color: var(--antage-blue-600);
  --main-color-darker: var(--antage-blue-800);
  --main-color-darkest: var(--antage-blue-900);
  --main-color-lighter: var(--antage-blue-300);
  --main-color-lightest: var(--antage-blue-100);

  /* the shade from each other palette most similar to antage blue */
  --main-color-complement: var(--antage-orange-900);
  --purple: var(--antage-purple-400);
  --pink: var(--antage-pink-400);
  --green: var(--antage-green-200);
  --indigo: var(--antage-indigo-600);

  /* useful alternate shades */
  --main-color-complement-light: var(--antage-orange-600);
  --main-color-complement-lighter: var(--antage-orange-200);
  --light-pink: var(--antage-pink-100);
  --light-green: var(--antage-green-100);

  --default-font: "Noto Sans", sans-serif;

  /* used to set margins */
  --ta__sidenav-width: 20rem;
  --sidenav-width: 12rem;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.mat-tooltip {
  font-size: 14px !important;
}

.mat-header-cell,
.mat-cell {
  padding-right: 10px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: black !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: red !important;
  background-color: var(--main-color) !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: var(--main-color) !important;
}

.mat-stroked-button {
  color: white;
  background-color: #3f86cc;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #3f86cc;
}
